import { TSelectOptionTypeUi } from 'antd/components/Select/Select.types';
import { formatStateLabel } from 'antd/helpers/utils';
import { TFetchConverter } from '../types/converter.types';

export const supportedStatesConverter: TFetchConverter<TSelectOptionTypeUi, string> = {
  fromDb: (data) => {
    return {
      value: data.toUpperCase(),
      label: formatStateLabel(data),
    };
  },
};

import { useEffect } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'antd/redux/store';
import appSlice from 'antd/redux/slice/app.slice';
import SidebarRoutes from './SidebarRoutes';
import RedirectToDefault from './components/RedirectToDefault';
import { defaultPagePath } from '../utils/enums';
import { RoutesNames } from './RoutesNames';

const PrivateRoute = ({ component: Component, usersData, allowedUserTypes, ...rest }) => {
  const { userType, impersonateOn } = usersData || {};
  const history = useHistory();
 const redirectLink = useAppSelector((state) => state.appSlice.currentNotificationUrl);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (redirectLink) {
      history.push(redirectLink);
      dispatch(appSlice.actions.setCurrentNotificationUrl(''));
    }
  }, []);

  useEffect(() => {
    if (!usersData?.userType) {
      history.push(RoutesNames.SignIn);
    }
  }, [usersData]);

  return (
    <Route
      {...rest}
      render={(props) =>
        usersData?.userType ? (
          allowedUserTypes.indexOf(userType) !== -1 ? (
            <SidebarRoutes userType={userType} isLoggedIn>
              <Component {...props} />
            </SidebarRoutes>
          ) : (
            <Redirect to={defaultPagePath[userType].false} />
          )
        ) : (
          <RedirectToDefault />
        )
      }
    />
  );
};

export default PrivateRoute;

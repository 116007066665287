import { Colors } from 'core/CssVariables';
import {
  EAnimalCharginStatusDB,
  EAnimalCharginStatusUI,
  EAnimalTagsUI,
  EConversionStatusDB,
  EConversionStatusUI,
} from '../types/animals.types';
import { EGender, EPetQuantityFilterDB, EPetQuantityFilterUI, TStatusMapper } from '../types/general.types';
import { EResidencyStatusDB, EResidencyStatusUI } from '../types/residence.types';
import { EPropertyStatusUI } from '../types/property.types';
import { ELandlordStatusDB, ELandlordStatusUI } from '../types/landlord.types';

export enum EText {
  DEFAULT_ERROR = 'Something went wrong',
  HP_FORM_TEXT = 'The quickest and most effective method of verifying your documentation is with the Health Professional Form attached below sent immediately to your Health Professional. It does not ask any specific details about the nature of your disability, only that the process is in accordance to the HUD guidelines. If you would prefer to verify the documentation another way please contact us at support@ourpetpolicy.com or (208)906-8886.',
}

export const yesNoOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const yesNoTrueFalseOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const onOfOptions = [
  { value: true, label: 'On' },
  { value: false, label: 'Off' },
];
export const hudGuidelinesLink =
  'https://www.hud.gov/sites/dfiles/PA/documents/HUDAsstAnimalNC1-28-2020.pdf?fbclid=IwAR3NcG1FMmTGaw7LZ__oMwWku7wWu4R51n6AbCyHw9tgUQgi95KiMc41C4Y';

export const chargingStatusMapper: TStatusMapper<EAnimalCharginStatusUI> = {
  [EAnimalCharginStatusUI.BEING_CHARGED]: {
    bgColor: Colors.SuccessColor,
  },
  [EAnimalCharginStatusUI.GRACE_PERIOD]: {
    bgColor: Colors.Blue,
  },
  [EAnimalCharginStatusUI.NOT_APPLICABLE]: {
    bgColor: Colors.DarkGrey,
  },
  [EAnimalCharginStatusUI.NOT_BEING_CHARGED]: {
    bgColor: Colors.ErrorColor,
  },
  [EAnimalCharginStatusUI.SHOULD_NOT]: {
    bgColor: Colors.PrimaryColor,
  },
  [EAnimalCharginStatusUI.UNSPECIFIED]: {
    bgColor: Colors.LightGrey,
  },
};

export const oldHud =
  'https://www.hud.gov/sites/dfiles/PA/documents/HUDAsstAnimalNC1-28-2020.pdf?fbclid=IwAR3NcG1FMmTGaw7LZ__oMwWku7wWu4R51n6AbCyHw9tgUQgi95KiMc41C4Y';

export const animalTypes = [
  { label: 'All', value: '' },
  { label: 'Pet', value: 'PET' },
  { label: 'Esa', value: 'ESA' },
  { label: 'Sa', value: 'SA' },
];
export const conversionStatuses = [
  { label: 'All', value: '' },
  {
    label: EConversionStatusUI.PENDING_ESA,
    value: EConversionStatusDB.PENDING_ESA,
  },
  {
    label: EConversionStatusUI.PENDING_SA,
    value: EConversionStatusDB.PENDING_SA,
  },
  { label: EConversionStatusUI.NONE, value: EConversionStatusDB.NONE },
];

export const residencyStatusesOptions = [
  { label: EResidencyStatusUI.RESIDENT, value: EResidencyStatusDB.RESIDENT },
  { label: EResidencyStatusUI.APPLICANT, value: EResidencyStatusDB.APPLICANT },
  {
    label: EResidencyStatusUI.FUTURE_RESIDENT,
    value: EResidencyStatusDB.FUTURE_RESIDENT,
  },
  {
    label: EResidencyStatusUI.FORMER_RESIDENT,
    value: EResidencyStatusDB.FORMER_RESIDENT,
  },
  { label: EResidencyStatusUI.DENIED, value: EResidencyStatusDB.DENIED },
  { label: EResidencyStatusUI.RETRACTED, value: EResidencyStatusDB.RETRACTED },
  { label: EResidencyStatusUI.NOTICE, value: EResidencyStatusDB.NOTICE },
];

export const petStatusOptions = [
  { label: 'Yes', value: 'HAS_ANIMAL' },
  { label: 'No', value: 'HAS_NOT_ANIMAL' },
  { label: 'Not specified', value: 'NOT_SPECIFIED' },
];

export const numberOfAnimalsOptions = [
  { label: 'All', value: '' },
  { label: EPetQuantityFilterUI.ZERO, value: EPetQuantityFilterDB.ZERO },
  {
    label: EPetQuantityFilterUI.MORE_THAN_ZERO,
    value: EPetQuantityFilterDB.MORE_THAN_ZERO,
  },
];

export const employeeOptions = [
  { label: 'All', value: '' },

  { label: 'Yes', value: true },
  {
    label: 'No',
    value: 'false',
  },
];
export const unitAnimalPresenceOptions = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
  { label: 'Unspecified', value: 'UNSPECIFIED' },
  { label: 'Conflict', value: 'CONFLICT' },
  { label: 'Not Applicable', value: 'NOT_APPLICABLE' },
];

export const animalTagsInfo = {
  Known: ' Animal known to be living in the property at the time the property was registered in OurPetPolicy.',
  PNC: 'Previously Not Charged animal that wasn’t initially paying fees but started paying due to OurPetPolicy.',
};

export const residentTagsInfo = {
  Known: 'Tenant residing in the property at the time the property was registered in OurPetPolicy.',
};

export const charginStatusValues = [
  {
    label: EAnimalCharginStatusUI.BEING_CHARGED,
    value: EAnimalCharginStatusDB.BEING_CHARGED,
  },
  {
    label: EAnimalCharginStatusUI.GRACE_PERIOD,
    value: EAnimalCharginStatusDB.GRACE_PERIOD,
  },
  {
    label: EAnimalCharginStatusUI.NOT_APPLICABLE,
    value: EAnimalCharginStatusDB.NOT_APPLICABLE,
  },
  {
    label: EAnimalCharginStatusUI.NOT_BEING_CHARGED,
    value: EAnimalCharginStatusDB.NOT_BEING_CHARGED,
  },
  {
    label: EAnimalCharginStatusUI.SHOULD_NOT,
    value: EAnimalCharginStatusDB.SHOULD_NOT,
  },
  {
    label: EAnimalCharginStatusUI.UNSPECIFIED,
    value: EAnimalCharginStatusDB.UNSPECIFIED,
  },
];

export const addedAnimalsTagValues = [
  { label: 'PNC', value: 'PNC' },
  { label: 'CNC', value: 'CNC' },
  { label: 'Converted', value: 'CONVERTED' },
];

export const allAndCurrentAnimalsTableTagValues = [
  { label: 'Known', value: 'KNOWN' },
  { label: 'Discovered', value: 'DISCOVERED' },
];

export const pendingAnimalsTableTagValues = [
  { label: 'Discovered', value: 'DISCOVERED' },
  { label: 'Known', value: 'KNOWN' },
];

export const animalTagsTootipTitles = {
  [EAnimalTagsUI.PNC]:
    'Previously Not Charged pet that wasn’t initially paying fees but started paying due to OurPetPolicy.',
  [EAnimalTagsUI.KNOWN]:
    'Animal known to be living in the property at the time the property was registered in OurPetPolicy.',
};

export const propertyStatusColor = {
  [EPropertyStatusUI.ACTIVE]: Colors.SuccessColor,
  [EPropertyStatusUI.PENDING]: Colors.WarningColor,
};

export const propertyStatusActionColor = {
  [EPropertyStatusUI.ACTIVE]: Colors.Grey,
  [EPropertyStatusUI.PENDING]: Colors.Black,
};
export const genderOption = [
  { value: EGender.MALE, label: 'Male' },
  { value: EGender.FEMALE, label: 'Female' },
];
export const propertyRemoveConfirmText = 'REMOVE PROPERTY';

export const vaccinationData = ['Rabies', 'Parvo', 'Spayed/Neutered', 'Dog liability insurance'];

export const hasESADocumentationOptions = [
  { value: true, label: 'I have ESA health documentation' },
  { value: false, label: `I don't have ESA health documentation` },
];

export const hasSADocumentationOptions = [
  { value: true, label: 'I have health professional documentation' },
  { value: false, label: `I don't have health professional documentation` },
];

export const hasSATrainerDocumentationOptions = [
  { value: true, label: 'I have SA trainer documentation' },
  { value: false, label: 'I don’t have SA trainer documentation' },
];

export const propertyManagementStatusOptions = [
  {
    label: ELandlordStatusUI.ACTIVE,
    value: ELandlordStatusDB.ACTIVE,
    isSelected: true,
  },
  { label: ELandlordStatusUI.INACTIVE, value: ELandlordStatusDB.INACTIVE },
  {
    label: ELandlordStatusUI.NON_LANDLORD,
    value: ELandlordStatusDB.NON_LANDLORD,
  },
  { label: ELandlordStatusUI.PROSPECT, value: ELandlordStatusDB.PROSPECT },
  { label: ELandlordStatusUI.TEST, value: ELandlordStatusDB.TEST },
];

export enum EConversionReasonDB {
  SEVERAL_ATTEMPTS_TO_VERIFY = 'SEVERAL_ATTEMPTS_TO_VERIFY',
  NOT_RECEIVED_DOCUMENTATION = 'NOT_RECEIVED_DOCUMENTATION',
  NOT_REQUIRED_TO_ALLEVIATE = 'NOT_REQUIRED_TO_ALLEVIATE',
  WAS_NOT_PROVIDED_BY_ITSELF = 'WAS_NOT_PROVIDED_BY_ITSELF',
  NOT_REQUIRED_FOR_DISABILITY = 'NOT_REQUIRED_FOR_DISABILITY',
}
export enum EConversionReasonUI {
  SEVERAL_ATTEMPTS_TO_VERIFY = 'Several attempts have been made to verify a disability-related need for an assistance animal with the healthcare provider, but regrettably, the necessary cooperation has not been received. Consequently, this animal will have to be considered a pet.',
  NOT_RECEIVED_DOCUMENTATION = 'No documentation was received from a healthcare provider in support of the reasonable accommodation request. Consequently, this animal will have to be considered a pet.',
  NOT_REQUIRED_TO_ALLEVIATE = 'It has been confirmed that this animal is not required to alleviate symptoms of a disability, therefore, the criteria for a reasonable accommodation has not been met.',
  WAS_NOT_PROVIDED_BY_ITSELF = 'The documentation provided was not, by itself, sufficient to reliably establish that an individual has a non-observable disability and a disability-related need for an assistance animal. Consequently, this animal will have to be considered a pet.',
  NOT_REQUIRED_FOR_DISABILITY = 'It was reported by the owner that the animal was not needed because of a disability. If the animal is not needed because of a disability, it does not qualify for a reasonable accommodation request.',
}

export enum EApproveWarningText {
  ALLOW = 'You are going to approve an animal which is not verified. Would you like to proceed?',
  BLOCK = 'Approval of assistance animals can only proceed after the system verifies them as ESA or SA, marked by a "Verified" status. Approving unverified assistance animals may result in a loss of pet rent and fees if the verification fails and the animal is converted to PET. If you have any questions or believe the animal should be approved, please contact our support team at support@ourpetpolicy.com.',
}

import type { TOptionTypeUi } from 'antd/components/EditableFields/EditableFields.types';
import {
  docHtmlConvertor,
  generatedPolicyDataConverter,
  policyContentConverter,
  policyOptionConverter,
} from 'antd/data/converters/policy.converters';
import {
  TPageableDataWithContentUi,
  TPageableDataWithContent,
  TPageableDataParamUI,
} from 'antd/data/types/pagableData.type';
import {
  TCreateSuggestionUI,
  TDocHtmlUI,
  TEditSuggestionUI,
  TGeneratedPolicyDataDB,
  TGeneratedPolicyDataUI,
  TPolicyContentGetDB,
  TPolicyContentGetUI,
  TPolicyGetShortData,
  TSuggestionTextUI,
} from 'antd/data/types/policy.types';
import { EPolicyUrls } from 'antd/data/urls/policy.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import { EAdminUrl } from 'antd/data/urls/admin.url';
import { authSplitApi } from '../helpers/slice.helpers';
import { EPropertyUrl } from '../../data/urls/property.url';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPolicyUrls.POLICY}`;
const adminUrl = `${EAdminUrl.ADMIN}/${EPolicyUrls.STATE_POLICY}`;
const templateUrl = `${EAdminUrl.ADMIN}/${EPolicyUrls.APPLICATION_SAMPLE_TEMPLATE}`;
const suggestionsUrl = `${EAdminUrl.ADMIN}/${EPolicyUrls.POLICY_CONTENT_SUGGESTION}`;

export const policyApi = authSplitApi('policy', [
  'policyOptions',
  'genericPolicy',
  'singlePolicy',
  'policyContent',
  'suggestions',
]).injectEndpoints({
  endpoints: (build) => ({
    getPolicyOptions: build.query<TOptionTypeUi[], void>({
      query() {
        return {
          url: `${baseUrl}/${EPolicyUrls.SEARCH}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TPolicyGetShortData[]) => {
        return data?.map((item) => policyOptionConverter.fromDb(item)) || [];
      },
      providesTags: ['policyOptions'],
    }),
    getGenericPolicy: build.query<TGeneratedPolicyDataUI, void>({
      query() {
        return {
          url: `${baseUrl}/${EPolicyUrls.GENERIC}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TGeneratedPolicyDataDB) => generatedPolicyDataConverter.fromDb(data),
      providesTags: ['genericPolicy'],
    }),
    generateGenericPolicy: build.mutation<TGeneratedPolicyDataUI, string>({
      query(policyId) {
        return {
          url: `${baseUrl}/${EPolicyUrls.GENERIC}`,
          method: 'PATCH',
          body: {
            policyId,
          },
        };
      },
      invalidatesTags: ['genericPolicy'],
    }),
    assignPolicy: build.mutation<TGeneratedPolicyDataUI, { propertyId: string; policyId: string }>({
      query({ policyId, propertyId }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTIES}/${EPolicyUrls.POLICY}`,
          method: 'PATCH',
          body: {
            policyId,
            propertyId,
          },
        };
      },
      invalidatesTags: ['singlePolicy'],
    }),
    getSinglePolicyAccessCodes: build.query<TGeneratedPolicyDataUI, string>({
      query(policyId) {
        return {
          url: `${baseUrl}/${policyId}/${EPolicyUrls.ACCESS_CODE}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TGeneratedPolicyDataDB) => generatedPolicyDataConverter.fromDb(data),
      providesTags: ['singlePolicy'],
    }),
    getDocHtml: build.query<TDocHtmlUI, { animalType: string; policyId: string }>({
      query({ animalType, policyId }) {
        return {
          url: `${baseUrl}/${EPolicyUrls.DOC}/${animalType}`,
          method: 'GET',
          responseHandler: 'text',
          params: { policyId },
        };
      },
      transformResponse: (data: string) => docHtmlConvertor.fromDb(data),
    }),
    getPolicyMetadata: build.query<any, void>({
      query() {
        return {
          url: `${import.meta.env.REACT_APP_HOSTNAME}/api/v1/${EPolicyUrls.RENTALS}/${EPolicyUrls.METADATA}`,
          method: 'GET',
        };
      },
    }),
    getPolicies: build.query<TPageableDataWithContentUi<TPolicyContentGetUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${baseUrl}`,
          method: 'GET',
          params: queryParamConverter(search, pagination),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TPolicyContentGetDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => policyContentConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['policyContent'],
    }),
    deletePolicy: build.mutation<void, { policyId: string }>({
      query({ policyId }) {
        return {
          url: `${baseUrl}/${policyId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['policyContent', 'policyOptions'],
    }),
    getPolicy: build.query<TPolicyContentGetUI, { policyId: string }>({
      query({ policyId }) {
        return {
          url: `${baseUrl}/${policyId}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TPolicyContentGetDB) => policyContentConverter.fromDb(data),
    }),
    updatePolicy: build.mutation<void, { policyId: string }>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['policyContent', 'policyOptions'],
    }),
    createPolicy: build.mutation<void, { policyId: string }>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['policyContent', 'policyOptions'],
    }),
    getSupportedStates: build.query<string[], void>({
      query() {
        return {
          url: `${baseUrl}/${EPolicyUrls.SUPPORTED_STATES}`,
          method: 'GET',
        };
      },
      transformResponse: (data: string[]) =>
        data.map((state) =>
          state
            .toLowerCase()
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        ),
    }),
    getSupportedStatesAdmin: build.query<string[], void>({
      query() {
        return {
          url: `${adminUrl}/${EPolicyUrls.SUPPORTED_STATES}`,
          method: 'GET',
        };
      },
      transformResponse: (data: string[]) =>
        data.map((state) =>
          state
            .toLowerCase()
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        ),
    }),
    getStateAdmin: build.query<string[], { state: string }>({
      query(body) {
        const state = body.state.replace(' ', '_');
        return {
          url: `${adminUrl}?state=${state}`,
          method: 'GET',
        };
      },
    }),
    updateStateAdmin: build.mutation<string[], { state: string }>({
      query(body) {
        const state = body.state.replace(' ', '_');
        return {
          url: `${adminUrl}?state=${state}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    createStateAdmin: build.mutation<string[], { state: string }>({
      query(body) {
        const state = body.state.replace(' ', '_');
        return {
          url: `${adminUrl}?state=${state}`,
          method: 'POST',
        };
      },
    }),
    getTemplates: build.query<{ docType: string; preSignedUrl: string }[], void>({
      query() {
        return {
          url: `${templateUrl}`,
          method: 'GET',
        };
      },
    }),
    uploadTemplate: build.mutation<{ docType: string; preSignedUrl: string }[], void>({
      query(body) {
        return {
          url: `${templateUrl}`,
          method: 'POST',
          body,
        };
      },
    }),
    getSuggestions: build.query<TSuggestionTextUI[], void>({
      query() {
        return {
          url: `${suggestionsUrl}`,
          method: 'GET',
        };
      },
      providesTags: ['suggestions'],
    }),
    deleteSuggestion: build.mutation<void, { policyContentSuggestionId: string }>({
      query(body) {
        return {
          url: `${suggestionsUrl}`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['suggestions'],
    }),
    createSuggestion: build.mutation<void, TCreateSuggestionUI>({
      query(body) {
        return {
          url: `${suggestionsUrl}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['suggestions'],
    }),
    editSuggestion: build.mutation<void, TEditSuggestionUI>({
      query(body) {
        return {
          url: `${suggestionsUrl}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['suggestions'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPolicyOptionsQuery,
  useGetDocHtmlQuery,
  useGenerateGenericPolicyMutation,
  useLazyGetGenericPolicyQuery,
  useGetPolicyMetadataQuery,
  useAssignPolicyMutation,
  useLazyGetSinglePolicyAccessCodesQuery,
  useGetPoliciesQuery,
  useDeletePolicyMutation,
  useLazyGetPolicyQuery,
  useUpdatePolicyMutation,
  useCreatePolicyMutation,
  useGetSupportedStatesQuery,
  useGetSupportedStatesAdminQuery,
  useGetStateAdminQuery,
  useUpdateStateAdminMutation,
  useCreateStateAdminMutation,
  useGetTemplatesQuery,
  useUploadTemplateMutation,
  useGetSuggestionsQuery,
  useCreateSuggestionMutation,
  useDeleteSuggestionMutation,
  useEditSuggestionMutation,
} = policyApi;

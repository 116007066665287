import { lazy, Suspense, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { useGetPaymentMethod } from 'api/apiHooks/paymentHooks';
import GlobalRoutes from 'routes/GlobalRoutes';
import ThemeWrapper from 'antd/theme/ThemeWrapper';
import theme from './components/theme';
import { GlobalStyle } from './GlobalStyles';
import ErrorBoundary from './ErrorBoundry';
import { useAppDispatch } from './contexts/PropertyEditContext';
import { initialize } from './googleAnalytics';
import { RoutesNames } from './routes/RoutesNames';
import SuspenseLoading from './routes/components/SuspenseLoading';
import PrivateRoutes from './routes/PrivateRoutes';
import PublicRoute from './routes/PublicRoutes';
import { RoutesData } from './routes/RoutesData';
import { NotificationsRedirectLayer } from './antd/componentsShared/NotificationsRedirectLayer/NotificationsRedirectLayer';
import { useGetUsersQuery } from './antd/redux/apiSlices/user.slice';
import { useAppSelector } from './antd/redux/store';
import { EUserRolesUI } from './antd/data/types/user.types';
import engMessages from './locales/english.json';
import ImpersonationConfirmationModal from './routes/components/ImpersonationConfirmationModal';

const Impersonation = lazy(() => import('antd/pages/public/Impersonation'));

const Certificate = lazy(() => import('Pages/Certificate'));

initialize();

function App() {
  const dispatch = useAppDispatch();
  const usersData = useAppSelector((state) => state.usersSlice);
  const [open, setOpen] = useState(false);
  const { amplifyUser, users, userType } = usersData;
  const [path,setPath]=useState({})
  const { isLoading } = useGetUsersQuery(amplifyUser, {
    skip: !amplifyUser || amplifyUser?.challengeName === 'NEW_PASSWORD_REQUIRED',
    refetchOnMountOrArgChange: true,
  });
  const profile = users?.[userType];

  const { refetch: getPaymentInfo } = useGetPaymentMethod((data) => {
    dispatch({ type: 'SET_CARD', payload: data });
  });
  useEffect(() => {
    function gtag() {
      window.dataLayer.push(arguments);
    }

    if (import.meta.env.REACT_APP_HOSTNAME === 'https://app.ourpetpolicy.com') {
      window.dataLayer = window.dataLayer || [];

      gtag('js', new Date());

      gtag('config', 'G-YE5YW0JR63');
    }
  }, []);
  useEffect(() => {
      if (window.location.pathname === RoutesNames.RedirectComplaint || window.location.pathname === RoutesNames.RedirectNotice) {
          setPath({
              search: window.location.search,
              pathname: window.location.pathname
          })
      }
      if (usersData.users !== null && path !== null) {
          if (path?.pathname === RoutesNames.RedirectComplaint || path?.pathname === RoutesNames.RedirectNotice) {
              const searchParams = new URLSearchParams(path?.search);

              const propertyManagementId = searchParams.get('propertyManagementId');
              const objectId = searchParams.get('objectId');


              if (usersData?.users?.landlord?.id !== propertyManagementId) {
                  setOpen(true)
              } else {
                  window.location.replace(
                      `${
              path.pathname === RoutesNames.RedirectComplaint
                ? RoutesNames.Complaint
                : RoutesNames.Notice
            }/${objectId}`
                  );
              }
          }

      }

  }, [usersData?.users])

  useEffect(() => {
    if (profile?.type === EUserRolesUI.LANDLORD) {
      getPaymentInfo({ userType: profile.type, params: { [`landlordId`]: profile.landlordId } });
    }
  }, [profile, dispatch]);
  useEffect(() => {
    dispatch({
      type: 'SET_PROFILE',
      payload: { ...profile, type: usersData?.userType },
    });
  }, [profile, dispatch]);
  if (isLoading) return <SuspenseLoading />;
  return (
    <IntlProvider locale='en' messages={engMessages}>
      <ErrorBoundary>
        <Router>
          <NotificationsRedirectLayer profile={profile}>
            <GlobalStyle />
            <ThemeProvider theme={theme}>
              <ThemeWrapper>
                <Switch>
                  {RoutesData.map((route, index) => {
                    const { path, exact, component, allowedUserTypes, isPrivate, isGlobal } = route;
                    if (isGlobal)
                      return (
                        <GlobalRoutes
                          usersData={usersData}
                          path={path}
                          exact={exact}
                          component={component}
                          key={index.toString()}
                        />
                      );

                    if (isPrivate) {
                      return (
                        <PrivateRoutes
                          usersData={usersData}
                          path={path}
                          exact={exact}
                          component={component}
                          allowedUserTypes={allowedUserTypes || ['landlord']}
                          key={index.toString()}
                        />
                      );
                    }
                    return (
                      <PublicRoute
                        usersData={usersData}
                        path={path}
                        exact={exact}
                        component={component}
                        key={index.toString()}
                      />
                    );
                  })}
                  <Route path={RoutesNames.Impersonation}>
                    <Suspense fallback={<SuspenseLoading />}>
                      <Impersonation />
                    </Suspense>
                  </Route>
                  <Route path={RoutesNames.Certificate}>
                    <Suspense fallback={<SuspenseLoading />}>
                      <Certificate />
                    </Suspense>
                  </Route>
                  <Route path='*'>
                    <Redirect to={RoutesNames.SignIn} />
                  </Route>
                </Switch>
                <ImpersonationConfirmationModal usersData={usersData}
                    open={open}
                    onCancel={()=>{
                        setOpen(false);
                        setPath(null)}} 
                  path={path}/>

              </ThemeWrapper>
            </ThemeProvider>
          </NotificationsRedirectLayer>
        </Router>
      </ErrorBoundary>
    </IntlProvider>
  );
}

export default App;

export enum EResidenceUrl {
  RESIDENCE = 'residence',
  VERIFY = 'verify',
  RESIDENCY = 'residency',
  APPROVE = 'approve',
  REJECT = 'reject',
  APPLICATION = 'application',
  PROFILE = 'profile',
  SEARCH = 'search',
  ANIMALS = 'animals',
  ANIMAL = 'animal',
  FILTER = 'filter',
  TERMINATE = 'terminate',
  TERMINATE_MOVED_IN_RESIDENCY = 'terminate-moved-in-residency',
  CURRENT = 'current',
  MOVE = 'move',
  DNA = 'dna',
  COLLECT = 'collect',
  UN_COLLECT = 'uncollect',
  EXPORT = 'export',
  STATISTICS = 'statistics',
  DASHBOARD = 'dashboard',
  CONVERTED = 'converted',
  PREVENTED = 'prevented',
  ALL = 'all',
  DUPLICATIONS = 'duplications',
  MOVE_IN = 'move-in',
  DETAILS = 'details',
  WITH_ROOMMATES = 'with-roommates',
  RETRACT = 'retract',
  REAPPLY = 'reapply',
  MOVED_OUT = 'move-out',
  REVOKE = 'revoke',
  SPECIES = 'species',
  COLORS = 'colors',
  BREEDS = 'breeds',
  ADDED_PETS = 'added-pets',
  SCREENING = 'screening',
  NOTICE = 'notice',
  REVOKE_TO_MOVE_IN = 'revoke-to-move-in',
  BULK = 'bulk',
  LEASE_DETAILS = 'lease-details',
  DOCUMENTS_DOWNLOAD = 'documents/download',
  MARK_AS_EMPLOYEE = 'mark-as-employee',
  UNMARK_AS_EMPLOYEE = 'unmark-as-employee',
}

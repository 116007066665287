import { formattedDate } from 'antd/helpers/utils';
import { TAddNewResidentForm } from 'antd/pages/private/Residents/ResidentsTable/components/AddNewResidentModal/AddNewResidentModal.types';
import { b64toBlob } from 'antd/api/sevices/fileServices';
import { TConvertor, TCreateConverter, TFetchConverter } from '../types/converter.types';
import {
  EAnimalResidencyStatusDB,
  EAnimalResidencyStatusUI,
  EPetStatusDB,
  EPetStatusUI,
  EPooCompaniesDB,
  EResidenceStatusDB,
  EResidenceStatusUi,
  EResidenceStatusesDB,
  EResidenceStatusesUI,
  EResidencyFilterStatusDB,
  EResidencyFilterStatusUI,
  EResidencyMoveInDB,
  EResidencyMoveInUI,
  EResidencyStatusDB,
  EResidencyStatusUI,
  EResidentHasAnimalDB,
  EResidentHasAnimalUI,
  EResidentTagsDB,
  EResidentTagsUI,
  TAddNewResidentDB,
  TAddNewResidentUI,
  TApplicantDetailsDB,
  TApplicantDetailsUI,
  TApplicantResidencyDB,
  TCollectDNA,
  TCollectDNAUi,
  TCompanyDB,
  TResidenceDB,
  TResidenceFilterDB,
  TResidenceFilterUI,
  TResidenceMoveInfo,
  TResidenceMoveInfoUI,
  TResidenceUI,
  TResidenciesOptionsUI,
  TResidencyApplicationApplyDB,
  TResidencyApplicationApplyUI,
  TResidencyApplicationRejectDB,
  TResidencyApplicationRejectUI,
  TResidentAnimalDB,
  TResidentAnimalUI,
  TResidentAnimalsDB,
  TResidentAnimalsUI,
  TResidentDetailsDB,
  TResidentDetailsUI,
  TResidentDuplicateDB,
  TResidentDuplicateUI,
  TResidentMove,
  TResidentMoveUi,
  TResidentProfileDB,
  TResidentProfileUI,
  TResidentResidencyDB,
  TResidentResidencyUI,
  TResidentSearchDB,
  TResidentSearchUI,
  TResidentsTableDB,
  TResidentsTableUI,
  TRetractAnimalDB,
  TRetractAnimalUI,
  TRevokeAnimalDB,
  TRevokeAnimalUI,
  TUnCollectDNA,
  TUnCollectDNAUi,
  TAnimalsScreeningUI,
  TAnimalsScreeningDB,
  TResidentNoticeUi,
  TResidentNotice,
  TMoveOutToResidentUi,
  TMoveOutToResidentDb,
  TMoveOutToNoticeUi,
  TMoveOutToNoticeDb,
  TBulkScreeningDB,
  TBulkScreeningUI,
  EUnitAnimalPresenceStatusDB,
  EUnitAnimalPresenceStatusUI,
} from '../types/residence.types';
import { animalStatusConverter, animalTypeConverter } from './animals.converter';
import {
  policyDataConverter,
  statusChangeInfoConverter,
  statusInfoWithResourceConverter,
  verificationStatusConverter,
} from './general.converters';
import { TOptionTypeUi } from '../types/general.types';

export const residenceStatusConverter: TConvertor<EResidenceStatusUi, EResidenceStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EResidenceStatusDB]: EResidenceStatusUi } = {
      [EResidenceStatusDB.MOVED_IN]: EResidenceStatusUi.MOVED_IN,
      [EResidenceStatusDB.MOVED_OUT]: EResidenceStatusUi.MOVED_OUT,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EResidenceStatusUi]: EResidenceStatusDB } = {
      [EResidenceStatusUi.MOVED_IN]: EResidenceStatusDB.MOVED_IN,
      [EResidenceStatusUi.MOVED_OUT]: EResidenceStatusDB.MOVED_OUT,
    };
    return dataMapper[data];
  },
};

export const residenceGetConverter: TFetchConverter<TResidenceUI, TResidenceDB> = {
  fromDb: (data) => {
    return {
      residenceId: data.residenceId,
      residentName: data.residentName,
      unit: {
        id: data.unit.id,
        number: data.unit.number,
      },
      property: {
        id: data.property.propertyId,
        name: data.property.name,
      },
      status: residenceStatusConverter.fromDb(data.status),
    };
  },
};

export const residenceStatusesConverter: TConvertor<EResidenceStatusesUI, EResidenceStatusesDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EResidenceStatusesDB]: EResidenceStatusesUI } = {
      [EResidenceStatusesDB.FUTURE_RESIDENT]: EResidenceStatusesUI.FUTURE_RESIDENT,
      [EResidenceStatusesDB.RESIDENT]: EResidenceStatusesUI.RESIDENT,
      [EResidenceStatusesDB.MOVED_OUT]: EResidenceStatusesUI.MOVED_OUT,
      [EResidenceStatusesDB.APPLICANT]: EResidenceStatusesUI.APPLICANT,
      [EResidenceStatusesDB.REJECTED]: EResidenceStatusesUI.REJECTED,
      [EResidenceStatusesDB.RETRACTED]: EResidenceStatusesUI.RETRACTED,
      [EResidenceStatusesDB.FORMER_RESIDENT]: EResidenceStatusesUI.FORMER_RESIDENT,
      [EResidenceStatusesDB.NOTICE]: EResidenceStatusesUI.NOTICE,
      [EResidenceStatusesDB.DENIED]: EResidenceStatusesUI.DENIED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EResidenceStatusesUI]: EResidenceStatusesDB } = {
      [EResidenceStatusesUI.FUTURE_RESIDENT]: EResidenceStatusesDB.FUTURE_RESIDENT,
      [EResidenceStatusesUI.RESIDENT]: EResidenceStatusesDB.RESIDENT,
      [EResidenceStatusesUI.MOVED_OUT]: EResidenceStatusesDB.MOVED_OUT,
      [EResidenceStatusesUI.APPLICANT]: EResidenceStatusesDB.APPLICANT,
      [EResidenceStatusesUI.REJECTED]: EResidenceStatusesDB.REJECTED,
      [EResidenceStatusesUI.RETRACTED]: EResidenceStatusesDB.RETRACTED,
      [EResidenceStatusesUI.FORMER_RESIDENT]: EResidenceStatusesDB.FORMER_RESIDENT,
      [EResidenceStatusesUI.NOTICE]: EResidenceStatusesDB.NOTICE,
      [EResidenceStatusesUI.DENIED]: EResidenceStatusesDB.DENIED,
    };
    return dataMapper[data];
  },
};

export const residenceFilterStatusesConverter: TConvertor<EResidencyFilterStatusUI, EResidencyFilterStatusDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in EResidencyFilterStatusDB]: EResidencyFilterStatusUI;
    } = {
      [EResidencyFilterStatusDB.APPROVED]: EResidencyFilterStatusUI.APPROVED,
      [EResidencyFilterStatusDB.MOVED_IN]: EResidencyFilterStatusUI.MOVED_IN,
      [EResidencyFilterStatusDB.PENDING]: EResidencyFilterStatusUI.PENDING,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in EResidencyFilterStatusUI]: EResidencyFilterStatusDB;
    } = {
      [EResidencyFilterStatusUI.APPROVED]: EResidencyFilterStatusDB.APPROVED,
      [EResidencyFilterStatusUI.MOVED_IN]: EResidencyFilterStatusDB.MOVED_IN,
      [EResidencyFilterStatusUI.PENDING]: EResidencyFilterStatusDB.PENDING,
    };
    return dataMapper[data];
  },
};

export const residenciesOptionsConverter: TFetchConverter<TResidenciesOptionsUI, TResidenceFilterDB> = {
  fromDb: (data) => ({
    label: `${data.residentName} ${data.propertyName || ''} ${data.unitNumber || ''}`,
    value: data.residenceIds[0],
    status: residenceStatusesConverter.fromDb(data.residencyIdToDetails[data.residenceIds[0]].status),
  }),
};

export const residenceFilterConverter: TFetchConverter<TResidenceFilterUI, TResidenceFilterDB> = {
  fromDb: (data) => {
    return {
      residenceProfile: `${data.residentName} ${data.propertyName || ''} ${data.unitNumber || ''}`,
      residenceIds: data.residenceIds,
      residencyApplicationIds: data.residencyApplicationIds || [],
      residenciesOptions: residenciesOptionsConverter.fromDb(data),
      moveInDate: data.moveInDate,
    };
  },
};

export const residenceMoveConverter: TFetchConverter<TResidenceMoveInfoUI, TResidenceMoveInfo> = {
  fromDb: (data) => {
    return {
      isPolicyChanging: data.isPolicyChanging,
    };
  },
};

export const ResidentMoveConverter: TCreateConverter<TResidentMoveUi, TResidentMove> = {
  toDb: (data) => {
    return {
      residenceId: data.residenceId,
      propertyId: data.property.value,
      unitNumber: data.unit.label,
      ...(data.endTime && {
        leaseTo: formattedDate(data.endTime, 'YYYY-MM-DD'),
      }),
      ...(data.startTime && {
        moveIn: formattedDate(data.startTime, 'YYYY-MM-DD'),
      }),
    };
  },
};

export const residentsSearchGetConverter: TFetchConverter<TResidentSearchUI, TResidentSearchDB> = {
  fromDb: (data) => {
    return {
      name: data.name,
      value: data.residentId,
    };
  },
};

export const companyNameConverter: TConvertor<TOptionTypeUi, TCompanyDB> = {
  toDb: (data) => {
    const isOther =
      data.value && ![EPooCompaniesDB.POO_PRINTS, EPooCompaniesDB.MR_DOG_POOP].includes(data.value as EPooCompaniesDB);
    return {
      companyOption: isOther ? EPooCompaniesDB.OTHER : data.value,
      companyCustomName: isOther ? data.value : null,
    };
  },
  fromDb: (data) => {
    const isOther = data.companyOption === EPooCompaniesDB.OTHER;
    return {
      value: (isOther ? data.companyCustomName : data.companyOption) || '',
      label: (isOther ? data.companyCustomName : data.companyOption) || '',
    };
  },
};

export const collectDnaConverter: TCreateConverter<TCollectDNAUi, TCollectDNA> = {
  toDb: (data) => {
    return {
      residenceId: data.residenceId,
      animalResidencyId: data.animalResidencyId,
      ...(data.company && {
        company: companyNameConverter.toDb(data.company),
      }),
      ...(data.dnaNumber && {
        dnaNumber: data.dnaNumber,
      }),
    };
  },
};

export const unCollectDnaConverter: TCreateConverter<TUnCollectDNAUi, TUnCollectDNA> = {
  toDb: (data) => {
    return {
      residenceId: data.residenceId,
      animalResidencyId: data.animalResidencyId,
    };
  },
};

export const animalResidencyStatusConverter: TConvertor<EAnimalResidencyStatusUI, EAnimalResidencyStatusDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in EAnimalResidencyStatusDB]: EAnimalResidencyStatusUI;
    } = {
      [EAnimalResidencyStatusDB.PENDING]: EAnimalResidencyStatusUI.PENDING,
      [EAnimalResidencyStatusDB.ARCHIVED]: EAnimalResidencyStatusUI.ARCHIVED,
      [EAnimalResidencyStatusDB.NOT_APPROVED]: EAnimalResidencyStatusUI.NOT_APPROVED,
      [EAnimalResidencyStatusDB.APPROVED]: EAnimalResidencyStatusUI.APPROVED,
      [EAnimalResidencyStatusDB.MOVED_OUT]: EAnimalResidencyStatusUI.MOVED_OUT,
      [EAnimalResidencyStatusDB.RETRACTED]: EAnimalResidencyStatusUI.RETRACTED,
      [EAnimalResidencyStatusDB.MOVED_IN]: EAnimalResidencyStatusUI.MOVED_IN,
      [EAnimalResidencyStatusDB.CONDITIONALLY_MOVED_IN]: EAnimalResidencyStatusUI.CONDITIONALLY_MOVED_IN,
      [EAnimalResidencyStatusDB.CONDITIONALLY_APPROVED]: EAnimalResidencyStatusUI.CONDITIONALLY_APPROVED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in EAnimalResidencyStatusUI]: EAnimalResidencyStatusDB;
    } = {
      [EAnimalResidencyStatusUI.PENDING]: EAnimalResidencyStatusDB.PENDING,
      [EAnimalResidencyStatusUI.ARCHIVED]: EAnimalResidencyStatusDB.ARCHIVED,
      [EAnimalResidencyStatusUI.NOT_APPROVED]: EAnimalResidencyStatusDB.NOT_APPROVED,
      [EAnimalResidencyStatusUI.APPROVED]: EAnimalResidencyStatusDB.APPROVED,
      [EAnimalResidencyStatusUI.MOVED_OUT]: EAnimalResidencyStatusDB.MOVED_OUT,
      [EAnimalResidencyStatusUI.RETRACTED]: EAnimalResidencyStatusDB.RETRACTED,
      [EAnimalResidencyStatusUI.MOVED_IN]: EAnimalResidencyStatusDB.MOVED_IN,
      [EAnimalResidencyStatusUI.CONDITIONALLY_APPROVED]: EAnimalResidencyStatusDB.CONDITIONALLY_APPROVED,
      [EAnimalResidencyStatusUI.CONDITIONALLY_MOVED_IN]: EAnimalResidencyStatusDB.CONDITIONALLY_MOVED_IN,
    };
    return dataMapper[data];
  },
};

export const tenantResidencyStatusConverter: TConvertor<EResidencyStatusUI, EResidencyStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EResidencyStatusDB]: EResidencyStatusUI } = {
      [EResidencyStatusDB.FUTURE_RESIDENT]: EResidencyStatusUI.FUTURE_RESIDENT,
      [EResidencyStatusDB.FORMER_RESIDENT]: EResidencyStatusUI.FORMER_RESIDENT,
      [EResidencyStatusDB.RESIDENT]: EResidencyStatusUI.RESIDENT,
      [EResidencyStatusDB.APPLICANT]: EResidencyStatusUI.APPLICANT,
      [EResidencyStatusDB.DENIED]: EResidencyStatusUI.DENIED,
      [EResidencyStatusDB.RETRACTED]: EResidencyStatusUI.RETRACTED,
      [EResidencyStatusDB.NOTICE]: EResidencyStatusUI.NOTICE,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EResidencyStatusUI]: EResidencyStatusDB } = {
      [EResidencyStatusUI.FUTURE_RESIDENT]: EResidencyStatusDB.FUTURE_RESIDENT,
      [EResidencyStatusUI.FORMER_RESIDENT]: EResidencyStatusDB.FORMER_RESIDENT,
      [EResidencyStatusUI.RESIDENT]: EResidencyStatusDB.RESIDENT,
      [EResidencyStatusUI.APPLICANT]: EResidencyStatusDB.APPLICANT,
      [EResidencyStatusUI.DENIED]: EResidencyStatusDB.DENIED,
      [EResidencyStatusUI.RETRACTED]: EResidencyStatusDB.RETRACTED,
      [EResidencyStatusUI.NOTICE]: EResidencyStatusDB.NOTICE,
    };
    return dataMapper[data];
  },
};

export const residentHasAnimalConverter: TConvertor<EResidentHasAnimalUI, EResidentHasAnimalDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EResidentHasAnimalDB]: EResidentHasAnimalUI } = {
      [EResidentHasAnimalDB.HAS_ANIMAL]: EResidentHasAnimalUI.HAS_ANIMAL,
      [EResidentHasAnimalDB.HAS_NOT_ANIMAL]: EResidentHasAnimalUI.HAS_NOT_ANIMAL,
      [EResidentHasAnimalDB.NOT_SPECIFIED]: EResidentHasAnimalUI.NOT_SPECIFIED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EResidentHasAnimalUI]: EResidentHasAnimalDB } = {
      [EResidentHasAnimalUI.HAS_ANIMAL]: EResidentHasAnimalDB.HAS_ANIMAL,
      [EResidentHasAnimalUI.HAS_NOT_ANIMAL]: EResidentHasAnimalDB.HAS_NOT_ANIMAL,
      [EResidentHasAnimalUI.NOT_SPECIFIED]: EResidentHasAnimalDB.NOT_SPECIFIED,
    };
    return dataMapper[data];
  },
};

export const residentProfileDataConverter: TFetchConverter<TResidentProfileUI, TResidentProfileDB> = {
  fromDb: (data) => {
    return {
      name: data.name,
      profilePicture: data?.profilePicture || '',
      email: data?.email || '',
      phoneNumber: data?.phoneNumber || '',
    };
  },
};

export const residentResidencyDataConverter: TFetchConverter<TResidentResidencyUI, TResidentResidencyDB> = {
  fromDb: (data) => {
    return {
      id: data.residenceId,
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      unitName: data.unitName,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate, 'MM/DD/yyyy') : null,
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate, 'MM/DD/yyyy') : null,
      nextLeaseToDate: data.nextLeaseToDate ? formattedDate(data.nextLeaseToDate, 'MM/DD/yyyy') : null,
      unitId: data.unitId,
    };
  },
};

export const applicantResidencyDataConverter: TFetchConverter<TResidentResidencyUI, TApplicantResidencyDB> = {
  fromDb: (data) => {
    return {
      id: data.residencyApplicationId,
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      unitName: data.unitName,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate, 'MM/DD/yyyy') : null,
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate, 'MM/DD/yyyy') : null,
      nextLeaseToDate: data.nextLeaseToDate ? formattedDate(data.nextLeaseToDate, 'MM/DD/yyyy') : null,
      unitId: null,
    };
  },
};

export const residentAnimalDataConverter: TFetchConverter<TResidentAnimalUI, TResidentAnimalDB> = {
  fromDb: (data) => {
    return {
      type: animalTypeConverter.fromDb(data.type),
      name: data.name,
      profilePicture: data?.profilePicture || '',
      species: data?.species || '',
      breed: data?.breed || '',
      weight: data.weight ? `${data.weight.value} ${data.weight.unit}` : '',
      verificationStatus: verificationStatusConverter.fromDb(data.verificationStatus),
      residencyStatus: animalResidencyStatusConverter.fromDb(data.residencyStatus),
      id: data.id,
      existsNonApprovedAnimalApplication: data.existsNonApprovedAnimalApplication,
      statusChangeInfo: statusChangeInfoConverter.fromDb(data.statusChangeInfoDto),
      isSigned: data.isSigned,
      signedBy: data.signedBy || '',
      dateSigned: data.dateSigned ? formattedDate(data.dateSigned) : '',
      policyName: data.policyName,
      policyContent: data.policyContent ? b64toBlob(data.policyContent, 'text/html') : null,
      policyId: data.policyId,
    };
  },
};
export const residentTagsConvertor: TConvertor<EResidentTagsUI, EResidentTagsDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EResidentTagsDB]: EResidentTagsUI } = {
      [EResidentTagsDB.KNOWN]: EResidentTagsUI.KNOWN,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EResidentTagsUI]: EResidentTagsDB } = {
      [EResidentTagsUI.KNOWN]: EResidentTagsDB.KNOWN,
    };
    return dataMapper[data];
  },
};
export const residentDetailsConverter: TFetchConverter<TResidentDetailsUI, TResidentDetailsDB> = {
  fromDb: (data) => {
    return {
      propertyCustom: data.isPropertyCustom,
      isScreeningEnabled: data.isScreeningEnabled,
      residencyStatus: tenantResidencyStatusConverter.fromDb(data.residencyStatus),
      residentId: data.residentId,
      residentProfile: residentProfileDataConverter.fromDb({
        profilePicture: data.profilePicture,
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
      }),
      residentResidency: residentResidencyDataConverter.fromDb({
        residenceId: data.residenceId,
        propertyId: data.propertyId,
        propertyName: data.propertyName,
        unitName: data.unitName,
        moveInDate: data.moveInDate,
        leaseToDate: data.leaseToDate,
        nextLeaseToDate: data.nextLeaseToDate,
        unitId: data.unitId,
      }),
      policy: policyDataConverter.fromDb({
        policyId: data.policyId,
        policyName: data.policyName,
        policyState: data.policyState,
        dateSigned: null,
        isSigned: false,
        signedBy: null,
      }),
      petStatus: residentHasAnimalConverter.fromDb(data.petStatus),
      roommatePetStatus: residentHasAnimalConverter.fromDb(data.roommatePetStatus),
      animals: (data.animals || []).map((item) => residentAnimalDataConverter.fromDb(item)),
      residencyStatusChangeInfo: data.residencyStatusChangeInfo
        ? statusInfoWithResourceConverter.fromDb(data.residencyStatusChangeInfo)
        : null,
      tags: (data.tags || []).map((tag) => residentTagsConvertor.fromDb(tag)),
      statusUpdateLocked: data.statusUpdateLocked,
      animalCount: data.animalCount,
      roommateAnimalCount: data.roommateAnimalCount,
      isAutomaticMoveOutEnabled: data.isAutomaticMoveOutEnabled,
      isEmployee: data.isEmployee,
    };
  },
};

export const applicantDetailsConverter: TFetchConverter<TApplicantDetailsUI, TApplicantDetailsDB> = {
  fromDb: (data) => {
    return {
      isScreeningEnabled: data.isScreeningEnabled,
      residencyStatus: tenantResidencyStatusConverter.fromDb(data.residencyStatus),
      residentId: data.residentId,
      residencyApplicationId: data.residencyApplicationId,
      residentProfile: residentProfileDataConverter.fromDb({
        profilePicture: data.profilePicture,
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
      }),
      residentResidency: applicantResidencyDataConverter.fromDb({
        residencyApplicationId: data.residencyApplicationId,
        propertyId: data.propertyId,
        propertyName: data.propertyName,
        unitName: data.unitName,
        moveInDate: data.moveInDate,
        leaseToDate: data.leaseToDate,
        nextLeaseToDate: data.nextLeaseToDate,
      }),
      policy: policyDataConverter.fromDb({
        policyId: data.policyId,
        policyName: data.policyName,
        policyState: data.policyState,
        dateSigned: null,
        isSigned: false,
        signedBy: null,
      }),
      petStatus: residentHasAnimalConverter.fromDb(data.petStatus),
      roommatePetStatus: residentHasAnimalConverter.fromDb(data.roommatePetStatus),
      animals: (data.animals || []).map((item) => residentAnimalDataConverter.fromDb(item)),
      residencyStatusChangeInfo: data.residencyStatusChangeInfo
        ? statusInfoWithResourceConverter.fromDb(data.residencyStatusChangeInfo)
        : null,
    };
  },
};

export const residencyApplicationApproveConverter: TCreateConverter<
  TResidencyApplicationApplyUI,
  TResidencyApplicationApplyDB
> = {
  toDb: (data) => {
    return {
      tenantResidencyId: data.id,
      propertyName: data.propertyName.label,
      unitNumber: data.unitNumber?.label || null,
      moveInDate: formattedDate(data.moveInDate, 'YYYY-MM-DD'),
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate, 'YYYY-MM-DD') : null,
    };
  },
};

export const residencyApplicationRejectConverter: TCreateConverter<
  TResidencyApplicationRejectUI,
  TResidencyApplicationRejectDB
> = {
  toDb: (data) => {
    return {
      residencyId: data.id,
      reason: data.reason,
    };
  },
};

export const residentAnimalsConverter: TFetchConverter<TResidentAnimalsUI, TResidentAnimalsDB> = {
  fromDb: (data) => {
    return {
      id: data.applicationId,
      name: data.name,
      profilePicture: data.profilePicture,
      status: animalResidencyStatusConverter.fromDb(data.status),
      type: animalTypeConverter.fromDb(data.type),
      verificationStatus: verificationStatusConverter.fromDb(data.verificationStatus),
      specie: data.specie?.value,
      breed: data.breed,
    };
  },
};

export const residencyMoveInConverter: TCreateConverter<EResidencyMoveInUI, EResidencyMoveInDB> = {
  toDb: (data) => {
    return {
      tenantResidencyId: data.id,
      propertyId: typeof data.property.value === 'string' ? data.property.value : data.property.value.value,
      unitName: data.unit.label,
      leaseToDate: formattedDate(data.leaseToDate, 'YYYY-MM-DD'),
      moveInDate: formattedDate(data.moveInDate, 'YYYY-MM-DD'),
      applicationIds: data.applicationIds,
    };
  },
};

export const reApplyAnimalsConverter: TFetchConverter<TRetractAnimalUI, TRetractAnimalDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      name: data.name,
      profilePicture: data.profilePicture,
      status: animalResidencyStatusConverter.fromDb(data.status),
      type: animalTypeConverter.fromDb(data.type),
      verificationStatus: verificationStatusConverter.fromDb(data.verificationStatus),
      specie: data.specie?.value,
      breed: data.breed,
    };
  },
};

export const revokeAnimalsConverter: TFetchConverter<TRevokeAnimalUI, TRevokeAnimalDB> = {
  fromDb: (data) => {
    return {
      animalId: data.id,
      id: data.animalResidencyId,
      name: data.name,
      profilePicture: data.profilePicture,
      status: animalResidencyStatusConverter.fromDb(data.status),
      type: animalTypeConverter.fromDb(data.type),
      verificationStatus: verificationStatusConverter.fromDb(data.verificationStatus),
      specie: data.specie?.value,
      breed: data.breed,
    };
  },
};
export const petStatusConverter: TConvertor<EPetStatusUI, EPetStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EPetStatusDB]: EPetStatusUI } = {
      [EPetStatusDB.HAS_ANIMAL]: EPetStatusUI.HAS_ANIMAL,
      [EPetStatusDB.HAS_NOT_ANIMAL]: EPetStatusUI.HAS_NOT_ANIMAL,
      [EPetStatusDB.NOT_SPECIFIED]: EPetStatusUI.NOT_SPECIFIED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EPetStatusUI]: EPetStatusDB } = {
      [EPetStatusUI.HAS_ANIMAL]: EPetStatusDB.HAS_ANIMAL,
      [EPetStatusUI.HAS_NOT_ANIMAL]: EPetStatusDB.HAS_NOT_ANIMAL,
      [EPetStatusUI.NOT_SPECIFIED]: EPetStatusDB.NOT_SPECIFIED,
    };
    return dataMapper[data];
  },
};

export const unitAnimalPresenceStatusConverter: TConvertor<EUnitAnimalPresenceStatusUI, EUnitAnimalPresenceStatusDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in EUnitAnimalPresenceStatusDB]: EUnitAnimalPresenceStatusUI;
    } = {
      [EUnitAnimalPresenceStatusDB.YES]: EUnitAnimalPresenceStatusUI.YES,
      [EUnitAnimalPresenceStatusDB.NO]: EUnitAnimalPresenceStatusUI.NO,
      [EUnitAnimalPresenceStatusDB.UNSPECIFIED]: EUnitAnimalPresenceStatusUI.UNSPECIFIED,
      [EUnitAnimalPresenceStatusDB.CONFLICT]: EUnitAnimalPresenceStatusUI.CONFLICT,
      [EUnitAnimalPresenceStatusDB.NOT_APPLICABLE]: EUnitAnimalPresenceStatusUI.NOT_APPLICABLE,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in EUnitAnimalPresenceStatusUI]: EUnitAnimalPresenceStatusDB;
    } = {
      [EUnitAnimalPresenceStatusUI.YES]: EUnitAnimalPresenceStatusDB.YES,
      [EUnitAnimalPresenceStatusUI.NO]: EUnitAnimalPresenceStatusDB.NO,
      [EUnitAnimalPresenceStatusUI.UNSPECIFIED]: EUnitAnimalPresenceStatusDB.UNSPECIFIED,
      [EUnitAnimalPresenceStatusUI.CONFLICT]: EUnitAnimalPresenceStatusDB.CONFLICT,
      [EUnitAnimalPresenceStatusUI.NOT_APPLICABLE]: EUnitAnimalPresenceStatusDB.NOT_APPLICABLE,
    };
    return dataMapper[data];
  },
};

export const residentsTableConverter: TFetchConverter<TResidentsTableUI, TResidentsTableDB> = {
  fromDb: (data) => {
    return {
      customProperty: data.isPropertyCustom,
      actionActorName: data.actionActorName,
      actionReason: data.actionReason,
      actionTime: data.actionTime,
      canApprove: data.canApprove,
      canMoveIn: data.canMoveIn,
      canMoveOut: data.canMoveOut,
      canReject: data.canReject,
      canRevokeApproval: data.canRevokeApproval,
      canRevokeMoveIn: data.canRevokeMoveIn,
      email: data.email,
      id: data.id,
      leaseToDate: data.leaseToDate,
      moveInDate: data.moveInDate,
      name: data.name,
      numOfAnimals: data.numOfAnimals,
      petStatus: petStatusConverter.fromDb(data.petStatus),
      phone: data.phone,
      policyId: data.policyId,
      policyName: data.policyName,
      propertyId: data.propertyId,
      propertyName: data.propertyName,
      statusChangeInfo: data.statusChangeInfo ? statusInfoWithResourceConverter.fromDb(data.statusChangeInfo) : null,
      residencyStatus: tenantResidencyStatusConverter.fromDb(data.residencyStatus),
      tenantId: data.tenantId,
      unitId: data.unitId,
      unitNumber: data.unitNumber,
      canNotice: data.canNotice,
      canRevokeNotice: data.canRevokeNotice,
      numOfUnitAnimals: data.numOfUnitAnimals,
      unitAnimalPresenceStatus: data.unitAnimalPresenceStatus
        ? unitAnimalPresenceStatusConverter.fromDb(data.unitAnimalPresenceStatus)
        : null,
      isEmployee: data.isEmployee,
    };
  },
};

export const addNewResidentConverter: TCreateConverter<TAddNewResidentUI, TAddNewResidentDB> = {
  toDb: (data) => {
    return {
      emailAddress: data.emailAddress,
      leaseToDate: formattedDate(data.endTime, 'YYYY-MM-DD'),
      moveInDate: formattedDate(data.startTime, 'YYYY-MM-DD'),
      phoneNumber: data.phoneNumber ? `+${data.phoneNumber}` : '',
      name: data.name,
      policyId: data.policy.value,
      unitId: data.newUnitId || data.unit?.value,
      propertyId: data.property.value,
      status: data.status,
      needToScreen: data.needToScreen,
    };
  },
};

export const duplicateResidentConverter: TFetchConverter<TResidentDuplicateUI, TResidentDuplicateDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      username: data.username,
      name: data.name,
    };
  },
};
export const animalsScreeningConverter: TCreateConverter<TAnimalsScreeningUI, TAnimalsScreeningDB> = {
  toDb: (data) => {
    return {
      tenantResidencyId: data.tenantResidencyId,
      needToScreen: data.needToScreen,
    };
  },
};

export const ResidentNoticeConverter: TCreateConverter<TResidentNoticeUi, TResidentNotice> = {
  toDb: (data) => {
    return {
      tenantResidencyId: data.residenceId,
      shouldAutomaticallyMoveOutAfterNotice: !!data.shouldAutomaticallyMoveOutAfterNotice,
      ...(data.moveOutDate && {
        moveOutDate: formattedDate(data.moveOutDate, 'YYYY-MM-DD'),
      }),
    };
  },
};

export const moveOutToResidentConverter: TCreateConverter<TMoveOutToResidentUi, TMoveOutToResidentDb> = {
  toDb: (data) => {
    return {
      animalResidencyIds: data.animalResidencyIds,
      residencyId: data.residencyId,
      reason: data.reason,
    };
  },
};

export const moveOutToNoticeConverter: TCreateConverter<TMoveOutToNoticeUi, TMoveOutToNoticeDb> = {
  toDb: (data) => {
    return {
      ...moveOutToResidentConverter.toDb(data),
      moveOutDate: formattedDate(data.moveOutDate, 'YYYY-MM-DD'),
      shouldAutomaticallyMoveOutAfterNotice: !!data.shouldAutomaticallyMoveOutAfterNotice,
    };
  },
};

export const bulkScreeningConvertor: TCreateConverter<TBulkScreeningUI, TBulkScreeningDB> = {
  toDb: (data) => {
    return { filter: data.tenantResidencyIds, value: data.needToScreen };
  },
};

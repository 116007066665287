import { TFileDB, TFileSecureUI, TFileUI } from 'antd/data/types/general.types';
import { EStorageUrls } from 'antd/data/urls/storage.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { fileConverter } from 'antd/data/converters/general.converters';
import { EAdminUrl } from 'antd/data/urls/admin.url';
import { authSplitApi } from '../helpers/slice.helpers';

const URL = EPropertyMangerUrl.PROPERTY_MANAGER;

export const formDataCreator = (data: any) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key]));
  return formData;
};

export const storageApi = authSplitApi('storage', ['files']).injectEndpoints({
  endpoints: (build) => ({
    uploadFile: build.mutation<TFileUI, FormData>({
      query(body) {
        return {
          url: `${URL}/${EStorageUrls.STORAGE}/${EStorageUrls.FILES}`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (data: TFileDB) => {
        return fileConverter.fromDb(data);
      },
    }),
    uploadFileSecure: build.mutation<TFileSecureUI, { file: FormData; category: string }>({
      query(body) {
        return {
          url: `${EAdminUrl.ADMIN}/${EStorageUrls.STORAGE}/${EStorageUrls.FILES_SECURE}`,
          method: 'POST',
          body: formDataCreator(body),
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useUploadFileMutation, useUploadFileSecureMutation } = storageApi;

export const RoutesNames = {
  Analytics: '/analytics',
  Intro: '/intro',
  Profile: '/profile',
  Properties: '/properties',
  Contracts: '/contracts',
  ResidentDetails: '/residents/:id',
  ApplicantDetails: '/applicants/:id',
  Residents: '/residents',
  Applicants: '/applicants',
  Applications: '/applications/:id?',
  ApplicationsPage: '/applications',
  Visitations: '/visitations',
  Maintainers: '/maintainers',
  IndividualMaintainer: '/maintainers/:id',
  PropertyManagers: '/property-managers',
  PropertyManager: '/property-managers/:id',
  Violations: '/violations',
  Payment: '/settings',
  Integrations: '/integration-settings',
  PropertyIntegration: '/integration-settings/:type/:id',
  Resman: '/resman',
  Yardi: '/yardi',
  RealPage: '/real-page',
  Support: '/support',
  IndividualContract: '/contracts/:id',
  // Requests: '/requests',
  Animals: '/animals',
  AnimalsApplicantDetails: '/animals/applicant/:id',
  AddedPetsDetails: '/animals/added-pets/:id',
  AnimalCurrentDetails: '/animals/current/:id',
  AnimalMovedOutDetails: '/animals/moved-out/:id',
  AnimalApplicantDetails: '/animals/applicant/:id',
  AnimalsVisitationDetails: '/animals/visitation/:id',
  AnimalsAll: '/animals/all',
  AnimalsCurrent: '/animals/current',
  AnimalsMovedOut: '/animals/moved-out',
  AnimalsApplicant: '/animals/applicant',
  AddedPets: '/animals/added-pets',
  AnimalsPrevented: '/animals/prevented',
  AnimalsVisitation: '/animals/visitation',
  EmployeeAnimals: '/animals/employee',
  Policies: '/policies',
  DefaultPolices: '/default-policies',
  IndividualProperty: '/properties/:id/:tabName?',
  Onboarding: '/onboarding',
  Documents: '/documents',
  SuggestedTexts: '/suggested-texts',
  Landlords: '/landlords',
  IndividualLandlord: '/landlords/:id',
  Dashboard: '/dashboard',
  PolicyInput: '/policy-input',
  TenantPaymentSettings: '/payment-settings',
  TenantContractView: '/tenant-contracts/:id',
  TenantContracts: '/tenant-contracts',
  Curriculum: '/curriculum',
  SubCurriculum: '/curriculum/:curriculumId',
  CurriculumDetail: '/curriculum/:curriculumId/:id',
  StatePolicy: '/state-policy',
  ResetPassword: '/reset-password',
  SignUp: '/sign-up',
  SignIn: '/sign-in',
  Impersonation: '/impersonation-pm',
  Confirmation: '/confirmation',
  ResentTenantPassword: '/resent-tenant-password',
  Certificate: '/certificate/:courseId/:checkpointKey',
  Complaints: '/complaints',
  Complaint: '/complaints/complaint',
  Notice: '/complaints/notice',
  ComplaintDetails: '/complaints/complaint/:id',
  NoticeDetails: '/complaints/notice/:id',
  AllRoommates: '/roommates/:tenantResidencyId',
  Lease: '/lease',
  LeaseDetails: '/lease/:tenantResidencyId',
  RedirectComplaint:'/redirect/complaint',
  RedirectNotice:'/redirect/notice'
};
export const TabValues = {
  [RoutesNames.Intro]: 'intro',
  [RoutesNames.Analytics]: 'analytics',
  [RoutesNames.Profile]: 'profile',
  [RoutesNames.Properties]: 'properties',
  [RoutesNames.Contracts]: 'contracts',
  [RoutesNames.Maintainers]: 'maintainers',
  [RoutesNames.IndividualMaintainer]: 'maintainers',
  [RoutesNames.PropertyManagers]: 'maintainers',
  [RoutesNames.PropertyManager]: 'property-managers',
  [RoutesNames.Violations]: 'violations',
  [RoutesNames.Integrations]: 'settings',
  [RoutesNames.Payment]: 'settings',
  [RoutesNames.PropertyIntegration]: 'settings',
  [RoutesNames.Support]: 'support',
  [RoutesNames.IndividualContract]: 'contracts',
  [RoutesNames.Animals]: 'animals',
  [RoutesNames.AnimalCurrentDetails]: 'animals',
  [RoutesNames.AnimalMovedOutDetails]: 'animals',
  [RoutesNames.AnimalsApplicant]: 'animals',
  [RoutesNames.AnimalsPrevented]: 'animals',
  [RoutesNames.AddedPets]: 'animals',
  [RoutesNames.AnimalsMovedOut]: 'animals',
  [RoutesNames.AnimalsCurrent]: 'animals',
  [RoutesNames.AnimalsVisitation]: 'animals',
  [RoutesNames.EmployeeAnimals]:'animals',
  [RoutesNames.Applications]: 'applications',
  [RoutesNames.Residents]: 'residents',
  [RoutesNames.ResidentDetails]: 'residents',
  [RoutesNames.ApplicantDetails]: 'residents',
  [RoutesNames.Policies]: 'policies',
  [RoutesNames.DefaultPolices]: 'policies',
  [RoutesNames.IndividualProperty]: 'properties',
  [RoutesNames.Onboarding]: 'onboarding',
  [RoutesNames.Documents]: 'documents',
  [RoutesNames.SuggestedTexts]: 'suggested-texts',
  [RoutesNames.Landlords]: 'landlords',
  [RoutesNames.IndividualLandlord]: 'landlords',
  [RoutesNames.Dashboard]: 'dashboard',
  [RoutesNames.PolicyInput]: 'policy-input',
  [RoutesNames.TenantPaymentSettings]: 'payment-settings',
  [RoutesNames.TenantContractView]: 'tenant-contracts',
  [RoutesNames.TenantContracts]: 'tenant-contracts',
  [RoutesNames.Curriculum]: 'curriculum',
  [RoutesNames.CurriculumDetail]: 'curriculum',
  [RoutesNames.StatePolicy]: 'state-policy',
  [RoutesNames.ResetPassword]: 'reset-password',
  [RoutesNames.SignUp]: 'sign-up',
  [RoutesNames.Confirmation]: 'confirmation',
  [RoutesNames.ResentTenantPassword]: 'resent-tenant-password',
  [RoutesNames.Certificate]: 'certificate',
  [RoutesNames.Violations]: 'visitations',
  [RoutesNames.Complaints]: 'complaints',
  [RoutesNames.Complaint]: 'complaints',
  [RoutesNames.Notice]: 'complaints',
  [RoutesNames.NoticeDetails]: 'complaints',
  [RoutesNames.ComplaintDetails]: 'complaints',
  [RoutesNames.AllRoommates]: 'roommates',
  [RoutesNames.LeaseDetails]: 'lease',
};

import { EText } from 'antd/data/datas/genericData';
import { TRtkErrorType } from 'antd/data/types/api.types';
import { TPetCount, TRoommateAnimalDB } from 'antd/data/types/roommates.types';
import moment, { Moment } from 'moment';
import toast from 'antd/components/Notification';

export const defaultDateFormat = 'MM/DD/YYYY';
export const dateTimeFormat = 'MM/DD/YYYY HH:mm A';
export const verificationCodeLength = 6;

export const generateSpaceClassNames = (array: number[]) => {
  let classNames = ``;
  array.forEach((n, i) => {
    classNames += `
            .mx-${i} {
                margin: 0 ${n}px !important;
            }
            .my-${i} {
                margin: ${n}px 0 !important;
            }
            .m-${i} {
                margin: ${n}px !important;
            }
            .mt-${i} {
                margin-top: ${n}px !important;
            }
            .mb-${i} {
                margin-bottom: ${n}px !important;
            }
            .ml-${i} {
                margin-left: ${n}px !important;
            }
            .mr-${i} {
                margin-right: ${n}px !important;
            }
            .pt-${i} {
                padding-top: ${n}px !important;
            }
            .pb-${i} {
                padding-bottom: ${n}px !important;
            }
            .pl-${i} {
                padding-left: ${n}px !important;
            }
            .pr-${i} {
                padding-right: ${n}px !important;
            }
            .px-${i} {
                padding: 0 ${n}px !important;
            }
            .py-${i} {
                padding: ${n}px 0 !important;
            }
            .p-${i} {
                padding: ${n}px !important;
            }
        `;
  });

  return classNames;
};

export const formattedDate = (date?: Moment | string, format?: string) => {
  return moment(date).format(format || defaultDateFormat);
};

export const animalsTypeCounter = (data: TRoommateAnimalDB[], accum?: TPetCount) => {
  return data.reduce((data, animal) => {
    if (data[animal.type]) {
      data[animal.type]++;
    } else {
      data[animal.type] = 1;
    }
    return data;
  }, accum || { ESA: 0, PET: 0, SA: 0 });
};

export const formattedDateTimezone = (date: Moment | string, format?: string) => {
  return moment
    .utc(date)
    .local()
    .format(format || defaultDateFormat);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const catchRtkError = (e: any) => {
  toast({
    description: (e as TRtkErrorType)?.data?.message || EText.DEFAULT_ERROR,
    type: 'error',
  });
};

export const formatStateLabel = (input: string) => {
  return input
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

export const formatToSnakeCase = (input: string) => {
  return input.toUpperCase().replace(/ /g, '_');
};

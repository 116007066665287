import styled from 'styled-components';
import {
  Modal as UIKitModal,
  ModalDeleteConfirmation as UIKitModalDeleteConfirmation,
} from '@landlord-tech/ui-kit-opp/dist/components/Modal';

import { FontFamilies } from 'core/CssVariables';
import { TModalDeleteProps, TModalProps } from './Modal.types';

export const SModal = styled(UIKitModal)<TModalProps>`
  &&& {
    .ant-modal-title {
      font-family: ${FontFamilies.FontSecondary};
    }
  }
`;

export const SModalDeleteConfirmation = styled(UIKitModalDeleteConfirmation)<TModalDeleteProps>`
  &&& {
    z-index: 9;

    .ant-modal-title {
      font-family: ${FontFamilies.FontSecondary};
    }
  }
`;

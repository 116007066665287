import Modal, { ModalWidthEnum } from 'antd/components/Modal';
import {
  useDeleteImpersonationMutation,
  useImpersonateUserMutation,
} from 'antd/redux/apiSlices/user.slice';
import { RoutesNames } from 'routes/RoutesNames';
import { useHistory } from 'react-router-dom';
import { Text } from 'antd/components/Typography';
import useShowToaster from 'components/Hooks/useShowToaster';

const ImpersonationConfirmationModal = ({
  open,
  onCancel,
  usersData,
  path,
}: {
  open: boolean;
  onCancel: () => void;
  usersData: any;
  path: any;
}) => {
  const { impersonateOn } = usersData || {};

  const history = useHistory();
  const showToaster = useShowToaster();

  const search = path?.search ? new URLSearchParams(path.search) : null;
  const [impersonateUserData, { isLoading }] = useImpersonateUserMutation();
  const [deleteImpersonation, { isLoading: exitLoading }] =
    useDeleteImpersonationMutation();
  const impersonateForRedirection = async () => {
    if (search === null || path?.pathname === null) return;
    if (impersonateOn) {
      try {
        await deleteImpersonation().unwrap();
      } catch (e) {
        showToaster({
          type: 'error',
          title: 'Something went wrong!',
        });
      }
    }
    const searchParams = new URLSearchParams(path.search);

    const landlordId = searchParams.get('propertyManagementId');
    const objectId = searchParams.get('objectId');

    try {
      //@ts-ignore
      await impersonateUserData(landlordId).unwrap();
      history.push(
        `${
          path.pathname === RoutesNames.RedirectComplaint
            ? RoutesNames.Complaint
            : RoutesNames.Notice
        }/${objectId}`
      );
      onCancel();
    } catch (e) {
      showToaster({
        type: 'error',
        title: 'Something went wrong!',
      });
    }
  };
  return (
    <Modal
      centered
      title="Confirm impersonation"
      size={ModalWidthEnum.Medium}
      visible={open}
      okText="Impersonate"
      onOk={impersonateForRedirection}
      onCancel={onCancel}
      confirmLoading={false}
      destroyOnClose
    >
      <Text>
        You need to impersonate {search?.get('propertyManagementName')} first.
      </Text>
    </Modal>
  );
};

export default ImpersonationConfirmationModal;

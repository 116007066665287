import { TAmplifyError } from 'antd/data/types/amplify.types';
import {
  EUserRolesUI,
  TGetNotSyncedHubspotCountUI,
  TGetUserDataUI,
  THubspotIntegrationUI,
  TNoteDB,
  TNoteUI,
  TUserPropertyManagerUI,
} from 'antd/data/types/user.types';
import {
  getAdminUserService,
  getPropertyManagerUserService,
  getUserTypeDataService,
  impersonateUser,
} from 'antd/redux/apiServices/user.services';
import { EAdminUrl } from 'antd/data/urls/admin.url';
import { EUserUrls } from 'antd/data/urls/user.url';
import { hubspotEnableConverter } from 'antd/data/converters/user.converters';
import { TSignInAmplifyUI } from 'antd/data/types/auth.types';
import { authSplitApi } from '../helpers/slice.helpers';
import { clearAllCachedData } from '../store';

export const userApi = authSplitApi('user', [
  'userType',
  'users',
]).injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<TGetUserDataUI | null, TSignInAmplifyUI>({
      // @ts-ignore
      async queryFn(arg, { dispatch }, options, baseQuery) {
        try {
          const userType = await getUserTypeDataService(baseQuery);
          if (userType?.type === EUserRolesUI.ADMIN) {
            const adminUser = await getAdminUserService(baseQuery);
            if (adminUser?.impersonateOn) {
              const propertyManagerUser = await getPropertyManagerUserService(
                baseQuery
              );
              const usersData = {
                [EUserRolesUI.ADMIN]: adminUser,
                ...(propertyManagerUser?.type && {
                  [propertyManagerUser.type]: propertyManagerUser,
                }),
              };
              return {
                data: {
                  users: usersData,
                  userType: propertyManagerUser?.type,
                  impersonateOn: true,
                },
              };
            }
            return {
              data: {
                userType: userType.type,
                users: { [EUserRolesUI.ADMIN]: adminUser },
              },
            };
          }
          if (
            userType?.type === EUserRolesUI.LANDLORD ||
            userType?.type === EUserRolesUI.PROPERTY_MANAGER
          ) {
            const propertyManagerUser = await getPropertyManagerUserService(
              baseQuery
            );
            return {
              data: propertyManagerUser?.type
                ? {
                    userType: propertyManagerUser?.type,
                    users: { [propertyManagerUser.type]: propertyManagerUser },
                  }
                : null,
            };
          }
          return { data: null };
        } catch (e) {
          const error = e as TAmplifyError;
          return { error };
        }
      },
      providesTags: ['users'],
    }),
    impersonateUser: build.mutation<TUserPropertyManagerUI, null>({
      // @ts-ignore
      async queryFn(arg, { dispatch }, options, baseQuery) {
        try {
          clearAllCachedData();
          await impersonateUser(baseQuery, arg);
          const data = await getPropertyManagerUserService(baseQuery);
          return { data };
        } catch (e) {
          const error = e as TAmplifyError;
          return { error };
        }
      },
    }),
    deleteImpersonation: build.mutation<void, void>({
      query() {
        return {
          url: `${EAdminUrl.ADMIN}/${EUserUrls.PROFILE}/${EUserUrls.IMPERSONATION}`,
          method: 'DELETE',
        };
      },
    }),
    addNote: build.mutation<TNoteDB, TNoteUI>({
      query(body) {
        return {
          url: `${EAdminUrl.ADMIN}/${EUserUrls.NOTES}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['users'],
    }),
    editNote: build.mutation<TNoteDB, TNoteUI>({
      query(body) {
        return {
          url: `${EAdminUrl.ADMIN}/${EUserUrls.NOTES}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['users'],
    }),
    deleteNote: build.mutation<TNoteDB, TNoteUI>({
      query(body) {
        return {
          url: `${EAdminUrl.ADMIN}/${EUserUrls.NOTES}`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['users'],
    }),
    enableHubspotIntegration: build.mutation<void, THubspotIntegrationUI>({
      query(body) {
        return {
          url: `${EAdminUrl.ADMIN}/${EAdminUrl.PROPERTY_MANAGEMENT}/${EAdminUrl.HUB_SPOT}/${EAdminUrl.ENABLE}`,
          method: 'PATCH',
          body: hubspotEnableConverter.toDb(body),
        };
      },
      invalidatesTags: ['users'],
    }),
    disableHubspotIntegration: build.mutation<void, THubspotIntegrationUI>({
      query(body) {
        return {
          url: `${EAdminUrl.ADMIN}/${EAdminUrl.PROPERTY_MANAGEMENT}/${EAdminUrl.HUB_SPOT}/${EAdminUrl.DISABLE}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['users'],
    }),
    getNotSyncedCountHubspot: build.query<
      TGetNotSyncedHubspotCountUI,
      { propertyManagerId: string }
    >({
      query({ propertyManagerId }) {
        return {
          url: `${EAdminUrl.ADMIN}/${EAdminUrl.PROPERTY_MANAGEMENT}/${EAdminUrl.HUB_SPOT}/${EAdminUrl.NOT_SYNCED_ANIMALS_COUNT}/${propertyManagerId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetUsersQuery,
  useImpersonateUserMutation,
  useDeleteImpersonationMutation,
  useAddNoteMutation,
  useDeleteNoteMutation,
  useEditNoteMutation,
  useEnableHubspotIntegrationMutation,
  useDisableHubspotIntegrationMutation,
  useLazyGetNotSyncedCountHubspotQuery,
} = userApi;

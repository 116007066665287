export enum EPolicyUrls {
  POLICY = 'policy',
  DOC = 'doc',
  RENTALS = 'rentals',
  METADATA = 'metadata',
  GENERIC = 'generic',
  ACCESS_CODE = 'accessCode',
  SUPPORTED_STATES = 'supported-states',
  STATE_POLICY = 'state-policy',
  APPLICATION_SAMPLE_TEMPLATE = 'application-sample-template',
  TEMPLATE = 'template',
  POLICY_CONTENT_SUGGESTION = 'policy-content-suggestion',
  TABLE = 'table',
  SEARCH = 'search',
}
